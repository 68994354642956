import {
    AccountDetails,
    Country,
    DealPropertyRealEstateAtticType,
    DealPropertyRealEstateBathroomFacilityType,
    DealPropertyRealEstateGarageAdditionalInfoType,
    DealPropertyRealEstateGarageLocationType,
    DealPropertyRealEstateHeatingType,
    DealPropertyRealEstateMaterialType,
    DealPropertyRealEstateTerraceUsabilityType,
    DealPropertyRealEstateWinterGardenUsabilityType,
    JaNeinValue,
} from "../models";
import { extendWithNoOptionsValue } from "./util";

export const ACCOUNT_DETAILS_VALUES = Object.values(AccountDetails);

export const PROPERTY_REAL_ESTATE_BATHROOM_FACILITY_VALUES = Object.values(DealPropertyRealEstateBathroomFacilityType);

export const PROPERTY_REAL_ESTATE_HEATING_VALUES = Object.values(DealPropertyRealEstateHeatingType);

export const PROPERTY_REAL_ESTATE_WINTER_GARDEN_USABILITY_VALUES = Object.values(
    DealPropertyRealEstateWinterGardenUsabilityType,
);

export const PROPERTY_REAL_ESTATE_TERRACE_USABILITY_VALUES = Object.values(DealPropertyRealEstateTerraceUsabilityType);

export const PROPERTY_REAL_ESTATE_MATERIAL_VALUES = Object.values(DealPropertyRealEstateMaterialType);

export const PROPERTY_REAL_ESTATE_PREFABRICATED_CONSTRUCTION_VALUES = [JaNeinValue.JA, JaNeinValue.NEIN];

export const PROPERTY_REAL_ESTATE_GARAGE_LOCATION_VALUES = Object.values(DealPropertyRealEstateGarageLocationType);

export const PROPERTY_REAL_ESTATE_GARAGE_ADDITIONAL_INFO_VALUES = extendWithNoOptionsValue(
    Object.values(DealPropertyRealEstateGarageAdditionalInfoType),
);

export const PROPERTY_REAL_ESTATE_ATTIC_VALUES = Object.values(DealPropertyRealEstateAtticType);

export const TAX_DOMICILE_VALUES = [
    Country.AUSTRIA,
    Country.GERMANY,
    Country.SWITZERLAND,
    Country.SLOVAKIA,
    Country.SLOVENIA,
    Country.CZECH_REPUBLIC,
    Country.TURKEY,
    Country.HUNGARY,
    "Vereinigte Staaten von Amerika (USA)",
    "Sonstiges",
];
