import { DocumentMiraclDimension, DocumentTypeDimension, RequiredDocumentJobType } from "../models";

type SameKeyValueRecord<Keys extends string> = {
    [v in Keys]: v;
};

const inferGenericType = <T extends string>(obj: SameKeyValueRecord<T>) => {
    return obj;
};

export const documentCategoryDimensions = inferGenericType({
    bank: "bank",
    customer: "customer",
    property: "property",
    miracl: "miracl",
} as const);

export const documentBankDimensions = inferGenericType({
    erste: "erste",
    raiffeisen: "raiffeisen",
    oberbank: "oberbank",
    wüstenrot: "wüstenrot",
    HYPO: "HYPO",
} as const);

export const documentBorrowerDimensions = inferGenericType({
    kreditnehmer1: "kreditnehmer1",
    kreditnehmer2: "kreditnehmer2",
    kreditnehmer3: "kreditnehmer3",
    kreditnehmer4: "kreditnehmer4",
} as const);

export const documentPersonDimensions = inferGenericType({
    person1: "person1",
    person2: "person2",
    person3: "person3",
    person4: "person4",
});

export const documentBankTypeDimensions = inferGenericType({
    vertraglandesfoerderung: "vertraglandesfoerderung",
    aushaftungLandesfoerderung: "aushaftungLandesfoerderung",
    datenblatt: "datenblatt",
    liegenschaftsbewertung: "liegenschaftsbewertung",
    finanzierungsanfrage: "finanzierungsanfrage",
    vermittlungsauftrag: "vermittlungsauftrag",
    bedarfsanalyse: "bedarfsanalyse",
    DSGVO: "DSGVO",
    einverstandniserklarung: "einverstandniserklarung",
    selbstauskunft: "selbstauskunft",
    stellungnahme: "stellungnahme",
    liegenschaftsdatenblatt: "liegenschaftsdatenblatt",
    hikRGSelbstauskunft: "hikRGSelbstauskunft",
    HHPRLB: "HHPRLB",
    SC: "SC",
    kundenerklärung: "kundenerklärung",
    planRechnungDreiJahre: "planRechnungDreiJahre",
    bankSonstiges: "bankSonstiges",
} as const);

export const documentPersonalTypeDimensions = inferGenericType({
    reisepass: "reisepass",
    meldezettel: "meldezettel",
    lohnabrechnungen: "lohnabrechnungen",
    jahreslohnzettel: "jahreslohnzettel",
    eigenmittelnachweis: "eigenmittelnachweis",
    wiedereinstellungszusage: "wiedereinstellungszusage",
    dienstvertrag: "dienstvertrag",
    karenzgeldbescheid: "karenzgeldbescheid",
    vertragKonsumkredit: "vertragKonsumkredit",
    aktuelleAushaftungKonsumkredit: "aktuelleAushaftungKonsumkredit",
    schreibenGehaltserhöhung: "schreibenGehaltserhöhung",
    gebietskrankenkassenauszug: "gebietskrankenkassenauszug",
    pensionszettel: "pensionszettel",
    steuerbescheide: "steuerbescheide",
    steuererklärungen: "steuererklärungen",
    jahresabschlüsse: "jahresabschlüsse",
    saldenliste: "saldenliste",
    steuerkonto: "steuerkonto",
    firmenbuchauszug: "firmenbuchauszug",
    aufenthaltstitel: "aufenthaltstitel",
    nachweisFamilienbeihilfe: "nachweisFamilienbeihilfe",
    aktuellerAuszugSVA: "aktuellerAuszugSVA",
    nachweisZuZahlendeAlimente: "nachweisZuZahlendeAlimente",
    nachweisZuErhaltendeAlimente: "nachweisZuErhaltendeAlimente",
    kontoumsätze: "kontoumsätze",
    berufsUnfähigkeitsPensionsbescheid: "berufsUnfähigkeitsPensionsbescheid",
    kundenunterlagenSonstiges: "kundenunterlagenSonstiges",
    schufaAuskunft: "schufaAuskunft",
    abfertigung: "abfertigung",
    scheidungsvergleich: "scheidungsvergleich",
    scheidungsbeschluss: "scheidungsbeschluss",
    auszugOGK: "auszugOGK",
    kundenplanung: "kundenplanung",
    gesellschaftsvertrag: "gesellschaftsvertrag",
    rückzahlungsbestätigung: "rückzahlungsbestätigung",
    heiratsurkunde: "heiratsurkunde",
    asylbescheid: "asylbescheid",
    steuerkontoUnternehmen: "steuerkontoUnternehmen",
} as const);

export const documentObjectTypeDimensions = inferGenericType({
    expose: "expose",
    fotosAußen: "fotosAußen",
    fotosKüche: "fotosKüche",
    fotosBad: "fotosBad",
    fotosWohnzimmer: "fotosWohnzimmer",
    fotosSchlafzimmer: "fotosSchlafzimmer",
    fotosAndereRäume: "fotosAndereRäume",
    grundrisse: "grundrisse",
    grundbuch: "grundbuch",
    wohnungseigentumsvertrag: "wohnungseigentumsvertrag",
    schenkungsvertrag: "schenkungsvertrag",
    kaufanbot: "kaufanbot",
    kaufvertrag: "kaufvertrag",
    kreditvertrag: "kreditvertrag",
    aktuelleAushaftung: "aktuelleAushaftung",
    optionsvertrag: "optionsvertrag",
    mietvertrag: "mietvertrag",
    baukostenaufstellung: "baukostenaufstellung",
    energieausweis: "energieausweis",
    flächenwidmungsplan: "flächenwidmungsplan",
    fotosInnen: "fotosInnen",
    kostenvoranschlägeRechnungen: "kostenvoranschlägeRechnungen",
    löschungsquittung: "löschungsquittung",
    nachweisMieteinnahmen: "nachweisMieteinnahmen",
    gutachten: "gutachten",
    betriebskostenabrechnung: "betriebskostenabrechnung",
    objektunterlagenSonstiges: "objektunterlagenSonstiges",
    miraclSonstiges: "miraclSonstiges",
    einreichplan: "einreichplan",
    fotosKeller: "fotosKeller",
    fotosHeizung: "fotosHeizung",
    altePläne: "altePläne",
    bauAusstattungsbeschreibung: "bauAusstattungsbeschreibung",
} as const);

export const documentTypeDimensions = inferGenericType({
    ...documentBankTypeDimensions,
    ...documentPersonalTypeDimensions,
    ...documentObjectTypeDimensions,
} as const);

export const documentObjectDimensions = inferGenericType({
    object1: "object1",
    object2: "object2",
    object3: "object3",
    object4: "object4",
    object5: "object5",
} as const);

export const documentMiraclDimensions = inferGenericType({
    kreditangebot: "kreditangebot",
    miraclKreditvertrag: "miraclKreditvertrag",
    esis: "esis",
    kreditvergleich: "kreditvergleich",
    beratungsprotokoll: "beratungsprotokoll",
    pfandurkunde: "pfandurkunde",
    bankAustria: "bankAustria",
    ksvAuszug: "ksvAuszug",
    treuhandschreiben: "treuhandschreiben",
    angebot: "angebot",
    sepamandat: "sepamandat",
    kontovertrag: "kontovertrag",
    deckungsregister: "deckungsregister",
    schuldundpfandurkunde: "schuldundpfandurkunde",
    bezugsverpfändung: "bezugsverpfändung",
    kreditvertragZwischenfinanzierung: "kreditvertragZwischenfinanzierung",
    esisZwischenfinanzierung: "esisZwischenfinanzierung",
    documentMiraclSonstiges: "documentMiraclSonstiges",
} as const);

export const REQUIRED_DOCUMENT_JOB_TYPES = Object.values(RequiredDocumentJobType);

export const DOCUMENT_DESCRIPTIONS: Partial<Record<DocumentTypeDimension | DocumentMiraclDimension, string>> = {
    // Customer document types
    [documentTypeDimensions.reisepass]:
        "A passport is an official travel document issued by a government that " +
        "certifies a person's identity and nationality for international travel." +
        " A passport allows its bearer to enter and temporarily reside in a " +
        "foreign country, access local aid and protection, and obtain consular " +
        "assistance from their government.",
    [documentTypeDimensions.meldezettel]:
        "The Meldezettel is an application form on the basis of which " +
        "registration/deregistration/change of residence is carried out in the " +
        "Central Register of Residents. As confirmation of the completed registration, " +
        "the person required to register receives the Confirmation of Registration " +
        "(printout from the Central Register of Residents).",
    [documentTypeDimensions.aufenthaltstitel]:
        "A residence permit (less commonly residency permit) is a document or card " +
        "required in some regions, allowing a foreign national to reside in a country " +
        "for a fixed or indefinite length of time. These may be permits for temporary " +
        "residency, or permanent residency. The exact rules vary between regions.",
    [documentTypeDimensions.lohnabrechnungen]:
        "A payslip is a document thats given to an employee each payday. It shows " +
        "their total amount earned, less deductions for things like tax. Payslips " +
        "show income from salary, hourly wages or commission. They also list things like: " +
        "tax withheld, reimbursements for personal work expenses (like mileage or travel expenses)" +
        ",deductions such as insurance and retirement contributions,employer contributions " +
        "toward insurance or retirement",
    [documentTypeDimensions.jahreslohnzettel]:
        "Annual payslips are generally issued at the end of each year and include information " +
        "about your salary, wage tax, and social security contributions. You receive these from " +
        "your employer at the end of February of the following year at the absolute latest. ",
    [documentTypeDimensions.steuerbescheide]:
        "In short, the tax assessment notice is the tax authority's response to your tax return. " +
        "Every taxpayer who has submitted a tax return will receive such a tax assessment notice. " +
        "It lists all the information you provided in the return and, ideally, what you will get " +
        "refunded. Or, conversely, what you still need to pay.",

    // Object document types
    [documentTypeDimensions.expose]:
        "A real estate exposé is a text that describes and advertises a property. " +
        "It contains information about the location, equipment and price of the " +
        "property. The exposé is an important part of the real estate agents work.",
    [documentTypeDimensions.energieausweis]:
        "An energy certificate is a document that reflects the energy performance of a " +
        "building and the calculated energy consumption, including all gains and losses " +
        "while the facility is being used in certain climatic conditions. In other words, " +
        "it is a document that clearly presents the energy properties of a building, " +
        "such as heating, carpentry, wall quality, and climate.",
    [documentTypeDimensions.grundbuch]:
        "The land register extract is proof of ownership or any other right that is entered " +
        "in the land register.Based on this document you can find the details about some " +
        "specific property such as: who is the owner of the property, wheteher the property " +
        "has multiple owners, whether the real estates is encumbered with a mortgage or some " +
        "other encumbrance as well as details about the area and location of the property etc.",
    [documentTypeDimensions.grundrisse]:
        "A Grundrisse document, particularly in German-speaking countries like Germany and Austria, " +
        "typically refers to a floor plan or layout of a building or apartment. It is a detailed " +
        "drawing that provides an overview of the structure's design, showing the arrangement of " +
        "rooms, doors, windows, and sometimes furniture placement within a space.",

    // miracl document types
    [documentMiraclDimensions.esis]:
        "The purpose of the European Standardised Information Sheet (ESIS) is to provide customers " +
        "with relevant information taking into account their financial situation, needs, and " +
        "financing preferences. This document is presented to them on several occasions during the " +
        "course of their financing application before they are bound by a contract or loan offer. " +
        "The objective is to allow customers to become familiar with the offered" +
        "options and to compare various financing options as well as their characteristics.",
    [documentMiraclDimensions.miraclKreditvertrag]:
        "A credit agreement is a legally binding contract between two parties in which a loan is offered. " +
        "These agreements detail all the conditions of the loan and the repayment process and are " +
        "signed by both the Lender and the Borrower. Credit agreements are also often referred to as loan agreements.",
};

export const PROPERTIES_WITH_JOB_TYPE = ["lohnabrechnungen", "jahreslohnzettel"];

export const PROPERTIES_WITH_OBJECT_LIABILITY = ["kreditvertrag", "aktuelleAushaftung"];

export const PROPERTIES_WITH_CUSTOMER_LIABILITY = [
    "vertragKonsumkredit",
    "aktuelleAushaftungKonsumkredit",
    "rückzahlungsbestätigung",
    "nachweisZuZahlendeAlimente",
];

export const PROPERTIES_WITH_LIABILITY = [...PROPERTIES_WITH_OBJECT_LIABILITY, ...PROPERTIES_WITH_CUSTOMER_LIABILITY];
