import { User } from "firebase/auth";
import { config } from "../../config";
import { axiosApiInstance } from "../api/axiosApiInstance";
import { buildInternalRequestConfig } from "../api/helpers";
import { Company } from "@miraclapp/mortgaging-shared";

const base = `${config.server.url}`;

export const getCompanies = async (currentUser: User): Promise<{ data: Company[] }> => {
    const config = await buildInternalRequestConfig(currentUser);
    const response = await axiosApiInstance.get<Company>(`${base}/companies`, config);
    return response.data as unknown as { data: Company[] };
};

export type GetCompanyQuery = {
    includeBankCredentials: true;
};

export const getCompany = async (
    currentUser: User,
    companyId: string,
    query?: GetCompanyQuery,
): Promise<{ data: Company }> => {
    const config = await buildInternalRequestConfig(currentUser);
    const response = await axiosApiInstance.get<Company>(`${base}/companies/${companyId}`, {
        ...config,
        params: query,
    });
    return response.data as unknown as { data: Company };
};

export const postCompany = async (currentUser: User, payload: any): Promise<{ data: Company }> => {
    const config = await buildInternalRequestConfig(currentUser);
    const response = await axiosApiInstance.post<{ data: Company }>(`${base}/companies`, payload, config);
    return response.data;
};

export const patchCompany = async (currentUser: User, companyId: string, payload: any) => {
    const config = await buildInternalRequestConfig(currentUser);
    const response = await axiosApiInstance.patch<Company>(`${base}/companies/${companyId}`, payload, config);
    return response.data;
};

export const getCompanyLogoUrl = async (currentUser: User, companyId: string) => {
    const config = await buildInternalRequestConfig(currentUser);
    const response = await axiosApiInstance.get<string>(`${base}/companies/${companyId}/logo`, config);
    return response.data;
};

export const postCompanyLogo = async (
    currentUser: User,
    companyId: string,
    payload: any,
): Promise<{ data: Company }> => {
    const config = await buildInternalRequestConfig(currentUser, {
        headers: { "Content-Type": "multipart/form-data" },
    });
    const formData = new FormData();
    formData.append("files", payload);
    const response = await axiosApiInstance.post<{ data: Company }>(
        `${base}/companies/${companyId}/logo`,
        formData,
        config,
    );
    return response.data;
};
