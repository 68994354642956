import { ColorScheme } from "./colors";
import { PandadocCompanyTemplatesData } from "./pandadoc";

export type CompanyBankCredentials = {
    bankAustria: {
        apiKey: string;
        advisorId: string;
    };
    bawag: {
        personalNumber: string;
        username: string;
        password: string;
    };
    startBau: {
        personalNumber: string;
        username: string;
        password: string;
    };
};

export enum CompanyPermission {
    SUBMIT_BANK_AUSTRIA_DEAL = "submitBankAustriaDeal",
    SUBMIT_BAWAG_DEAL = "submitBawagDeal",
    SUBMIT_START_BAU_DEAL = "submitStartBauDeal",
}

export type Company = {
    id: string;
    name: string;
    address: string;
    logo?: string;
    colorScheme: ColorScheme;
    banksDisplayEnabled: boolean;
    pandadocTemplates: PandadocCompanyTemplatesData;
    supportedBanks: string;
    bankCredentials: CompanyBankCredentials;
    permissions: CompanyPermission[];
};
